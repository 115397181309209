<template>
  <b-navbar toggleable="lg" type="dark" class="navigation-wrapper navbar-dark">
    <div id="logo-wrapper">
      <a href="/">
        <logo class="logo" />
      </a>
    </div>

    <b-navbar-toggle target="nav_collapse" />
    <b-collapse is-nav id="nav_collapse">
      <b-navbar-nav class="ml-auto">
        <b-nav-item href="/" :class="{ selected: currentTab === 'home' }"
          >Home</b-nav-item
        >
        <!-- <b-nav-item @click="scrollTo('#services')">Services</b-nav-item> -->
        <!-- <b-nav-item v-if="homepage" @click="scrollTo('#team')">Team</b-nav-item>
        <b-nav-item v-if="!homepage" href="/team">Team</b-nav-item>-->
        <b-nav-item
          href="/services"
          :class="{ selected: currentTab === 'services' }"
          >Services</b-nav-item
        >
        <b-nav-item href="/team" :class="{ selected: currentTab === 'team' }"
          >Team</b-nav-item
        >
        <b-nav-item
          href="/careers"
          :class="{ selected: currentTab === 'careers' }"
          >Careers</b-nav-item
        >
        <b-nav-item
          href="/blog"
          :class="{ highlight: true, selected: currentTab === 'blog' }"
          >Blog</b-nav-item
        >
        <b-nav-item @click="scrollTo('#contact-us')">Contact Us</b-nav-item>
      </b-navbar-nav>
      <div class="social-wrapper">
        <SocialTwitterLink />
        <SocialGithubLink />
        <SocialLinkedinLink />
      </div>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  props: {
    homepage: Boolean,
    currentTab: String,
  },
  methods: {
    scrollTo(anchor) {
      // Tablet/Mobile
      if (window.innerWidth < 768) {
        setTimeout(() => {
          this.$scrollTo(anchor, 500, { offset: -25 });
        }, 300);
      } else {
        this.$scrollTo(anchor, 500, { offset: -25 });
      }
    },
  },
};
</script>

<style lang="scss">
.navbar-dark .navbar-nav {
  .nav-link {
    color: #fff;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 10px;
      right: 10px;
      bottom: 4px;
      background: #e91e63; // todo: use vars
      height: 2px;
      transition: all 160ms ease;
      transform: scaleX(0);

      @media (max-width: 767px) {
        display: none;
      }
    }

    &:hover,
    &:focus {
      color: #fff;
      &::before {
        transform: scaleX(1);
      }

      @media (max-width: 767px) {
        color: #e91e63; // todo: use vars
      }
    }

    @media (max-width: 1200px) {
      padding: 0;
    }

    @media (max-width: 767px) {
      display: inline-block;
    }
  }

  .selected {
    .nav-link {
      color: #e91e63; // [todo] use vars
    }
  }

  // .highlight {
  //   .nav-link {
  //     font-weight: 700;
  //   }
  // }
}

.navigation-wrapper {
  padding-top: 2rem;

  #logo-wrapper {
    font-size: 1.2rem;
    font-weight: 800;
    text-transform: uppercase;
    float: left;

    a {
      color: #fff;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .clear-fix {
    clear: both;
  }

  .nav-item {
    margin-left: 2.4rem;
    font-weight: 500;

    &:first-of-type {
      margin-left: 0;
    }
  }

  // [todo] Use Flex, just being lazy and floating for now - don't judge mehhhh!

  nav.top-nav {
    float: right;

    a {
      display: inline-block;
      padding: 0.5rem 2rem;
      color: #fff;
      font-weight: 800;

      &.selected {
        color: #e91e63;
      }
    }
  }
}
.social-link {
  margin: 0 0.75rem;

  svg {
    width: 18px;
    height: 18px;
    path {
      transition: all 80ms ease-in-out;
    }
  }

  &:hover svg path {
    // TODO: use vars
    fill: #e91e63;
  }
}

// Blog Specific
.blog-layout {
  .author-photo-wrapper {
    .social-link {
      svg {
        width: 25px;
        height: 25px;
      }
    }

    .author-name-section {
      line-height: 2rem;
    }
  }
}

@media (min-width: 768px) {
  .social-wrapper {
    margin-left: 2rem;
  }
}

@media (max-width: 992px) {
  .navigation-wrapper .nav-item {
    margin-left: 0.8rem;
  }
}

@media (max-width: 768px) {
  .navbar-nav {
    margin-top: 1rem;
  }

  .navigation-wrapper .nav-item,
  .navigation-wrapper .nav-item:first-of-type {
    margin: 0.4rem 0;
  }

  .navigation-wrapper .social-wrapper {
    margin-top: 1rem;
    margin-left: 0;
  }

  .navigation-wrapper .social-link {
    margin: 0 0.5rem;
  }
}
</style>
