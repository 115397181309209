<template>
  <div class="contactus-wrapper" id="contact-us">
    <div class="container">
      <h2 class="heading">
        Write us a message
        <span class="trilon-pink heading-dot">.</span>
      </h2>

      <p class="subheading">
        Let's talk about how Trilon can help your next project get to the next
        level.
      </p>

      <!-- <div class="alert alert-success mt-3" role="alert">
        This is a success alert—check it out!
      </div>

      <div class="alert alert-danger mt-3" role="alert">
        Something went wrong!
      </div>-->

      <!-- <form id="contact-form" @submit.prevent="submitForm"> -->
      <form id="contact-form">
        <div class="contact-us-form row">
          <div class="height-control col-sm-12 col-md-4">
            <div class="row">
              <input
                v-model="name"
                type="text"
                class="form-control"
                placeholder="First name"
              />
            </div>
            <div class="row">
              <input
                v-model="email"
                type="email"
                class="form-control"
                placeholder="E-mail address"
              />
            </div>
            <div class="row">
              <input
                v-model="phone"
                type="phone"
                class="form-control"
                placeholder="Phone number"
              />
            </div>
          </div>
          <div class="text-control height-control col-sm-12 col-xs-12 col-md-8">
            <textarea
              v-model="message"
              type="text"
              class="form-control"
              placeholder="Your Message"
            ></textarea>
          </div>
        </div>

        <div class="contact-submit-wrapper contact-us-form text-right">
          <div class="text-right">
            <!-- <div class="form-checkbox mr-5">
              <input v-model="sendCopy" type="checkbox" id="send-copy-check">
              <label for="send-copy-check">Send me a copy</label>
            </div>-->

            <!-- <button type="submit" class="btn btn-primary">Send message -->
            <!-- message.replace(/\n/g, '%0D%0A') c -->
            <a
              :href="
                'mailto:hello@trilon.io?subject=Trilon%20Consulting&body=' +
                message.replace(/\n/g, '%0D%0A').replace(/\r/g, '%0D%0A') +
                '%0D%0A%0D%0A**************************%0D%0A%0D%0AName: ' +
                name +
                '%0D%0APhone: ' +
                phone +
                '%0D%0AEmail: ' +
                email
              "
              class="btn btn-primary"
            >
              Send message
              <arrow />
            </a>
          </div>
        </div>
      </form>

      <div class="other-contact-wrapper">
        <h3>Rather send us an email? Write to:</h3>

        <a href="mailto:hello@trilon.io" class="contact-email">
          <strong>hello</strong>@trilon.io
        </a>

        <!-- Hidden for now until we have a full-on Contact page
        <br>
        <a href="/contactus" class="more-info btn btn-secondary">More contact info</a>-->
      </div>
    </div>
  </div>
</template>

<script>
import Arrow from '~/components/Arrow.vue';

export default {
  components: {
    Arrow,
  },
  data() {
    return {
      // Form elements
      name: '',
      email: '',
      phone: '',
      message: '',
      sendCopy: false,

      submitting: false,
      isSubmitted: false,
      error: false,
    };
  },
  methods: {
    async submitForm(e) {
      this.submitting = true;
      e.preventDefault();

      // console.log(this.name);
      // console.log(this.email);
      // console.log(this.message);
      // console.log(this.phone);

      // this.$ga.event('submit', 'form');
      // this.error = false
      try {
        await this.$fetch.$post('/contact', {
          name: this.name,
          email: this.email,
          message: this.message,
          phone: this.phone,
          sendCopy: this.sendCopy,
        });
      } catch (ex) {
        this.submitting = false;
        this.error = true;
        console.error(ex);
      } finally {
      }
    },
  },
};
</script>

<style lang="scss">
.contactus-wrapper {
  background: url('../assets/bg-contactus-bottom.png') no-repeat bottom center,
    #050710;
  padding: 9rem 0 20rem;

  @media (min-width: 1440px) {
    background-size: contain;
  }

  // [todo] fix this whole mess :)
  .contact-us-form {
    max-width: 968px;
    margin: 2rem auto 0;

    .height-control {
      input,
      textarea {
        margin-top: 1.5rem;

        &::-webkit-scrollbar {
          width: 1em;
        }

        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        &::-webkit-scrollbar-thumb {
          background-color: #560aa7;
          outline: 1px solid #560aa7;
        }

        &::-webkit-scrollbar-corner {
          background-color: #560aa7;
        }
      }
    }

    .text-control {
      padding: 0;
    }

    textarea {
      height: 90%;
    }
  }

  .contact-submit-wrapper {
    padding-top: 3rem;
  }

  .other-contact-wrapper {
    margin: 5rem 0 0;

    .contact-email {
      color: #fff;
      font-size: 3rem;
      &:hover {
        color: #e91e63;
        border-bottom: 5px #e91e63 dotted;
      }

      @media (max-width: 768px) {
        font-size: 2.4rem;
      }
    }

    .more-info {
      margin-top: 4rem;
    }
  }

  .form-checkbox {
    display: inline-block;
  }

  @media (min-width: 768px) {
    .contact-us-form {
      .text-control {
        padding: 0 0 0 1.5rem;
      }
    }
  }

  @media (max-width: 768px) {
    .contact-us-form {
      textarea {
        margin-top: 1.5rem;
        min-height: 15rem;
      }
    }
  }
}
</style>
