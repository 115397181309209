<template>
  <section
    class="dark-bg wwd-wrapper"
    :class="{ 'margin-top-rwd': !disableMargin }"
  >
    <div id="services" :class="{ 'p-t-5': blogPage }">
      <div class="container">
        <h2 class="heading">
          What we do
          <span v-if="atTrilon">at Trilon </span>
          <span class="trilon-pink heading-dot">.</span>
        </h2>

        <p class="subheading">
          At Trilon, our goal is to help elevate teams - giving them the push
          they need to truly succeed in today's ever-changing tech world.
          <br />
          <br />
        </p>

        <!-- <p class="subheading">
          We strive for perfection, and are obsess over crafting the best user experiences and application
          architecture possible.
        </p>-->
        <div class="services row">
          <div class="service col-sm-6 col-lg-3">
            <img
              src="~/assets/icons/trilon-consulting.svg"
              class="wwd-icon"
              alt="Trilon - Consulting"
            />
            <h2>
              Consulting
              <span class="trilon-pink">.</span>
            </h2>
            <p class="gray">
              Let us help take your Application to the next level - planning the
              next big steps, reviewing architecture, and brainstorming with the
              team to ensure you achieve your most ambitious goals!
            </p>
          </div>
          <div class="service col-sm-6 col-lg-3">
            <img
              src="~/assets/icons/trilon-workshops.svg"
              class="wwd-icon"
              alt="Trilon - Development and Team Augmentation"
            />
            <h2>
              Development
              <span class="trilon-pink">.</span>
            </h2>
            <p class="gray">
              Trilon can become part of your development process, making sure
              that you're building enterprise-grade, scalable applications with
              best-practices in mind, all while getting things done better and
              faster!
            </p>
          </div>
          <div class="service col-sm-6 col-lg-3">
            <img
              src="~/assets/icons/trilon-training.svg"
              class="wwd-icon"
              alt="Trilon - Workshops on NestJS, Node, and other modern JavaScript topics"
            />
            <h2>
              Workshops
              <span class="trilon-pink">.</span>
            </h2>
            <p class="gray">
              <!-- Enterprise training & Workshops to get your team up to speed with the latest development
              paradigms ranging from RxJs, Front-end Architecture, TypeScript / JavaScript,
              NodeJS, Advanced NestJS, and much more.-->
              Have a Trilon team member come to YOU! Get your team up to speed
              with guided workshops on a huge variety of topics. Modern NodeJS
              (or NestJS) development, JavaScript frameworks, Reactive
              Programming, or anything in between! We've got you covered.
            </p>
          </div>
          <div class="service col-sm-6 col-lg-3">
            <img
              src="~/assets/icons/trilon-opensource.svg"
              class="wwd-icon"
              alt="Trilon - Open-source contributors"
            />
            <h2>
              Open-source
              <span class="trilon-pink">.</span>
            </h2>
            <p class="gray">
              We love open-source because we love giving back to the community!
              We help maintain & contribute to some of the largest open-source
              projects, and hope to always share our knowledge with the world!
            </p>
          </div>
        </div>

        <a href="/services" class="btn btn-primary mt-4">
          Explore more
          <arrow />
        </a>
        <!-- <a href="/services" class="btn btn-primary">Read more
          <arrow/>

        </a>-->
      </div>
    </div>
  </section>
</template>

<script>
import Arrow from '~/components/Arrow.vue';

export default {
  props: {
    atTrilon: Boolean,
    disableMargin: Boolean,
    blogPage: Boolean,
  },
  components: {
    Arrow,
  },
};
</script>

<style lang="scss">
.wwd-wrapper {
  padding-bottom: 10rem;

  .services {
    padding: 5rem 0 6.5rem;

    .service {
      margin-bottom: 3rem;
    }

    h2 {
      margin: 3rem 0 2rem;
      font-size: 1.5rem;

      span {
        margin-left: -0.2rem;
        font-size: 2rem;
      }
    }

    p {
      line-height: 1.75rem;
      font-size: 0.95rem;
      width: 90%;
      margin: 0 auto;
    }
  }

  .wwd-icon {
    max-width: 60px;
  }

  .arrow-down {
    transform: rotate(90deg);
  }
}

.margin-top-rwd {
  $min-width: 1520;
  @for $i from 0 through 12 {
    @media (min-width: #{$min-width + $i * 100}px) {
      margin-top: #{$i + 1}rem;
    }
  }
}

.p-t-5 {
  padding-top: 5rem;
}

.blog-layout {
  .wwd-wrapper {
    margin-top: 0;
  }
}
</style>
